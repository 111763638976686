.order-pick-container,
.order-pack-container,
.order-pickUp-container,
.order-history-container {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: grid;
  gap: 17px;
}

.order-card {
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
}

.order-details {
  padding: 20px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-bottom: 10px;
}

.order-info {
  flex: 0 0 200px;
  padding-right: 20px;
  border-right: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.order-products {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
}

.product-info {
  flex: 0 0 auto;
}

.product-detail,
.product-dimension,
.product-price {
  margin-bottom: 5px;
}

.product-title {
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 10px;
}

.product-price {
  margin-top: 10px;
  font-weight: bold;
}

.pack-order-button {
  padding: 10px 15px;
  margin-top: 10px;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pack-order-button:hover {
  background-color: rgb(69, 69, 253);
}

.order-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  justify-content: flex-end;
  gap: 17px;
}

.product-and-actions {
  flex: 1;
  padding-left: 20px;
}

.label-button {
  padding: 0px 16px;
  border: 1px solid #ccc;
  cursor: pointer;
  width: auto;
  border: 2px solid #ccc;
  border-radius: 8px;
}

.label-button:hover {
  background-color: #ccc;
  border: 2px solid #ccc;
}

.ship-order-button {
  padding: 0px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  width: auto;
  border: 2px solid transparent;
  border-radius: 8px;
}

.ship-order-button:hover {
  background-color: #0056b3;
  border: 2px solid transparent;
}

.product-image {
  max-width: 100%;
  max-height: 150px;
  height: auto;
  display: block;
  margin: 10px 0;
}